import MenuBookIcon from '@mui/icons-material/MenuBook'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Card from '@mui/material/Card'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import SortableList from 'components/SortableList'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from 'services/helpers'
import MDButton from '../../../components/MDButton'
import useTabs from '../../../utils/useTabs'
import { LessonItemHeader } from './LessonItemHeader'
import { QuestionItem } from './QuestionItem'
import { TaskGroupItem } from './TaskGroupItem'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

const QuestionTabContent = ({
  lesson,
  setSelectedLesson,
  setShowQuestionImportModal,
  hiddenXs,
  setShowQuestionModal,
  questions,
  setQuestions,
  renderDraggableQuestion,
  setQuestionsChanged,
  questionLoading,
  questionsChanged,
  onSaveQuestionOrder,
  saveOrderingLoading,
  setShowAIQuestionModal,
}) => {
  return (
    <>
      <MDBox
        display={'flex'}
        alignItems={'center'}
        gap={1}
        ml={2}
        mt={2}
        width={'100%'}
        // sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.25)' }}
      >
        <MDBox
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'end'}
          // sx={{ border: '2px solid red' }}
          gap={2}
          paddingBottom={1}
        >
          <MDButton
            variant="contained"
            onClick={() => {
              setSelectedLesson(lesson)
              setShowQuestionImportModal(true)
            }}
            minWidthButton={{ xs: '50px', md: '155px' }}
            sx={{
              boxShadow: 2,
              '&:hover': { boxShadow: 3 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hiddenXs && <MenuBookIcon />}
            {!hiddenXs && (
              <MDBox color="secondary" display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
                <MDTypography
                  sx={{ textWrap: 'nowrap', width: '120px' }}
                  variant="caption"
                  color="secondary"
                  fontWeight="bold"
                >
                  Question bank
                </MDTypography>
                <MenuBookIcon />
              </MDBox>
            )}
          </MDButton>
          <MDButton
            minWidthButton={{ xs: '50px', md: '155px' }}
            variant="contained"
            onClick={() => {
              setSelectedLesson(lesson)
              setShowQuestionModal({ isVisible: true, question: null })
            }}
            sx={{ boxShadow: 2, '&:hover': { boxShadow: 3 } }}
          >
            {hiddenXs && (
              <MDTypography variant="caption" color="secondary" fontWeight="bold">
                +
              </MDTypography>
            )}
            {!hiddenXs && (
              <MDTypography
                sx={{ textWrap: 'nowrap', width: '130px' }}
                variant="caption"
                color="secondary"
                fontWeight="bold"
              >
                Add Questionnaire +
              </MDTypography>
            )}
          </MDButton>
          <MDButton
            minWidthButton={{ xs: '50px', md: '155px' }}
            variant="contained"
            onClick={() => {
              setSelectedLesson(lesson)
              setShowAIQuestionModal(true)
            }}
            sx={{ boxShadow: 2, '&:hover': { boxShadow: 3 } }}
          >
            {hiddenXs && <AutorenewIcon />}
            {!hiddenXs && (
              <MDBox color="secondary" display={'flex'} flexWrap={'nowrap'}>
                <MDTypography
                  sx={{ textWrap: 'nowrap', width: '120px' }}
                  variant="caption"
                  color="secondary"
                  fontWeight="bold"
                >
                  AI Assistant
                </MDTypography>
                <AutoAwesomeIcon />
              </MDBox>
            )}
          </MDButton>
        </MDBox>
      </MDBox>
      <>
        <SortableList
          items={questions}
          setItems={setQuestions}
          renderItemComponent={renderDraggableQuestion}
          emptyMessage={'No questions found'}
          containerStyle={{ width: '100%', marginLeft: '1rem', marginTop: '.5rem' }}
          setItemsChanged={setQuestionsChanged}
          loading={questionLoading}
        />
        {questionsChanged && (
          <MDBox mt={3} style={{ width: '99%', display: 'flex', justifyContent: 'end' }}>
            <MDButton color="primary" onClick={onSaveQuestionOrder} loading={saveOrderingLoading}>
              Save ordering
            </MDButton>
          </MDBox>
        )}
      </>
    </>
  )
}

const TaskTabContent = ({
  lesson,
  setSelectedLesson,
  setShowTaskImportModal,
  setShowTaskGroupModal,
  hiddenXs,
  taskGroups,
  setTaskGroups,
  renderDraggableTaskGroup,
  setTaskGroupsOrderChanged,
  taskGroupsLoading,
  taskGroupsOrderChanged,
  onSaveTaskGroupOrder,
  taskGroupsOrderLoading,
}) => {
  return (
    <>
      <MDBox
        display={'flex'}
        alignItems={'center'}
        gap={1}
        ml={2}
        mt={2}
        width={'100%'}
        // sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.25)' }}
      >
        <MDBox
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'end'}
          // sx={{ border: '2px solid red' }}
          gap={2}
          paddingBottom={1}
        >
        <MDButton
          variant="contained"
          onClick={() => {
            setSelectedLesson(lesson)
            setShowTaskImportModal(true)
          }}
          minWidthButton={{ xs: '50px', md: '155px' }}
          sx={{
            boxShadow: 2,
            '&:hover': { boxShadow: 3 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {hiddenXs && <MenuBookIcon />}
          {!hiddenXs && (
            <MDBox color="secondary" display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
              <MDTypography
                sx={{ textWrap: 'nowrap', width: '120px' }}
                variant="caption"
                color="secondary"
                fontWeight="bold"
              >
                Task group bank
              </MDTypography>
              <MenuBookIcon />
            </MDBox>
          )}
        </MDButton>
        <MDButton
          minWidthButton={{ xs: '50px', md: '155px' }}
          variant="contained"
          onClick={() => {
            setSelectedLesson(lesson)
            setShowTaskGroupModal({ isVisible: true, taskGroup: null })
          }}
          sx={{ marginRight: 1, boxShadow: 2, '&:hover': { boxShadow: 3 } }}
        >
          {hiddenXs && (
            <MDTypography variant="caption" color="secondary" fontWeight="bold">
              +
            </MDTypography>
          )}
          {!hiddenXs && (
            <MDTypography
              sx={{ textWrap: 'nowrap', width: '130px' }}
              variant="caption"
              color="secondary"
              fontWeight="bold"
            >
              Add task group +
            </MDTypography>
          )}

        </MDButton>
      </MDBox>
      </MDBox>
      <>
        <SortableList
          items={taskGroups}
          setItems={setTaskGroups}
          renderItemComponent={renderDraggableTaskGroup}
          emptyMessage={'No tasks found'}
          containerStyle={{ width: '100%', marginLeft: '1rem', marginTop: '.5rem' }}
          setItemsChanged={setTaskGroupsOrderChanged}
          loading={taskGroupsLoading}
        />
        {taskGroupsOrderChanged && (
          <MDBox mt={3} style={{ width: '99%', display: 'flex', justifyContent: 'end' }}>
            <MDButton
              color="primary"
              onClick={onSaveTaskGroupOrder}
              loading={taskGroupsOrderLoading}
            >
              Save ordering
            </MDButton>
          </MDBox>
        )}
      </>
    </>
  )
}

export const LessonItem = ({
  lesson,
  setSelectedLesson,
  setShowLessonModal,
  setShowQuestionModal,
  setShowQuestionViewModal,
                             setShowTaskImportModal,
  setShowQuestionImportModal,
  setShowInstantMeetingModal,
  setShowTaskGroupModal,
  setShowDeleteLessonModal,
  setRefreshLessons,
  refreshQuestionsLessonId,
  setRefreshQuestionsLessonId,
  refreshTaskGroupsLessonId,
  setRefreshTaskGroupsLessonId,
  setShowAIQuestionModal,
}) => {
  const { courseId } = useParams()
  const api = useApi()
  const [collapsed, setCollapsed] = useState(false)
  // questions
  const [questions, setQuestions] = useState([])
  const [questionLoading, setQuestionsLoading] = useState(false)
  const [questionsChanged, setQuestionsChanged] = useState(false)
  const [saveOrderingLoading, setSaveOrderingLoading] = useState(false)
  // tasks
  const [taskGroups, setTaskGroups] = useState([])
  const [taskGroupsLoading, setTaskGroupsLoading] = useState(false)
  const [taskGroupsOrderChanged, setTaskGroupsOrderChanged] = useState(false)
  const [taskGroupsOrderLoading, setTaskGroupsOrderLoading] = useState(false)
  //
  const { tabValue, handleTabValueChange, CustomTabPanel, a11yProps } = useTabs()
  const theme = useTheme()
  const hiddenXs = useMediaQuery(theme.breakpoints.down('md'))

  const handleEdit = () => {
    setSelectedLesson(lesson)
    setShowLessonModal(true)
  }

  const handleDelete = () => {
    setSelectedLesson(lesson)
    setShowDeleteLessonModal(true)
  }

  const handleDuplicate = () => {
     api.duplicateLesson(courseId, lesson.id).handle({
       onSuccess: (res) => {
         setRefreshLessons(true)
       },
       errorMessage: 'Error duplicating lesson',
         successMessage: 'Lesson duplicated successfully!',
     })
  }

  const renderDraggableQuestion = (item, index) => (
    <QuestionItem
      key={item.id}
      question={item}
      index={index}
      setShowQuestionModal={setShowQuestionModal}
      lesson={lesson}
      setSelectedLesson={setSelectedLesson}
      setShowQuestionViewModal={setShowQuestionViewModal}
      setRefreshQuestionsLessonId={setRefreshQuestionsLessonId}
    />
  )

  const renderDraggableTaskGroup = (item, index) => (
    <TaskGroupItem
      key={item.id}
      taskGroup={item}
      index={index}
      setShowTaskGroupModal={setShowTaskGroupModal}
      lesson={lesson}
      setSelectedLesson={setSelectedLesson}
    />
  )

  const courseLessonQuestions = () => {
    setQuestionsLoading(true)
    api.listCourseLessonQuestions(courseId, lesson.id).handle({
      onSuccess: (res) => {
        setQuestions(res.data)
        setRefreshQuestionsLessonId(false)
      },
      errorMessage: 'Error getting questions',
      onFinally: () => setQuestionsLoading(false),
    })
  }

  const updateCourseLessonQuestionOrder = (data) => {
    setSaveOrderingLoading(true)
    api.updateCourseLessonQuestionOrder(Number(courseId), lesson.id, data).handle({
      onSuccess: (res) => {
        setShowQuestionModal({ isVisible: false, question: null })
      },
      errorMessage: 'Error updating questions order',
      successMessage: 'Questions order updated successfully!',
      onFinally: () => {
        setSaveOrderingLoading(false)
        setQuestionsChanged(false)
      },
    })
  }

  const onSaveQuestionOrder = () => {
    const data = {
      data: questions.map((q, qi) => ({ id: q.id, custom_order: q.custom_order })),
    }
    updateCourseLessonQuestionOrder(data)
  }

  const updateCourseLessonTaskGroupOrder = (data) => {
    setTaskGroupsOrderLoading(true)
    api.updateCourseLessonTaskGroupOrder(Number(courseId), lesson.id, data).handle({
      onSuccess: (res) => {
        setShowQuestionModal({ isVisible: false, question: null })
      },
      errorMessage: 'Error updating questions order',
      successMessage: 'Questions order updated successfully!',
      onFinally: () => {
        setTaskGroupsOrderLoading(false)
        setTaskGroupsOrderChanged(false)
      },
    })
  }

  const onSaveTaskGroupOrder = () => {
    const data = {
      data: taskGroups.map((tg, tgi) => ({ id: tg.id, custom_order: tg.custom_order })),
    }
    updateCourseLessonTaskGroupOrder(data)
  }

  const courseLessonTaskGroups = () => {
    setTaskGroupsLoading(true)
    api.listCourseLessonTaskGroups(courseId, lesson.id).handle({
      onSuccess: (res) => {
        setTaskGroups(res.data)
        setRefreshTaskGroupsLessonId(false)
      },
      errorMessage: 'Error getting tasks',
      onFinally: () => setTaskGroupsLoading(false),
    })
  }

  useEffect(() => {
    if (!collapsed) {
      return
    }
    //
    switch (tabValue) {
      case 0:
        courseLessonQuestions()
        break
      case 1:
        courseLessonTaskGroups()
        break
      default:
        courseLessonQuestions()
        break
    }
  }, [tabValue, collapsed])

  useEffect(() => {
    if (refreshQuestionsLessonId === lesson.id) {
      courseLessonQuestions()
    }
  }, [refreshQuestionsLessonId])

  useEffect(() => {
    if (refreshTaskGroupsLessonId === lesson.id) {
      courseLessonTaskGroups()
    }
  }, [refreshTaskGroupsLessonId])

  return (
    <Card sx={{ padding: '1rem' }}>
      <LessonItemHeader
        lesson={lesson}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        handleDelete={handleDelete}
        setSelectedLesson={setSelectedLesson}
        setShowInstantMeetingModal={setShowInstantMeetingModal}
        hiddenXs={hiddenXs}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
      />
      {/* =========== TABS =========== */}
      {collapsed && (
        <Box sx={{ width: '100%', marginTop: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="Lesson Tabs">
              <Tab label="Questions" {...a11yProps(0)} />
              <Tab label="Tasks" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <QuestionTabContent
              {...{
                lesson,
                setSelectedLesson,
                setShowQuestionImportModal,
                hiddenXs,
                setShowQuestionModal,
                questions,
                setQuestions,
                renderDraggableQuestion,
                setQuestionsChanged,
                questionLoading,
                questionsChanged,
                onSaveQuestionOrder,
                saveOrderingLoading,
                setShowAIQuestionModal,
              }}
            />
          </CustomTabPanel>
          {/*  */}
          <CustomTabPanel value={tabValue} index={1}>
            <TaskTabContent
              {...{
                lesson,
                setSelectedLesson,
                setShowTaskGroupModal,
                setShowTaskImportModal,
                hiddenXs,
                taskGroups,
                setTaskGroups,
                renderDraggableTaskGroup,
                setTaskGroupsOrderChanged,
                taskGroupsLoading,
                taskGroupsOrderChanged,
                onSaveTaskGroupOrder,
                taskGroupsOrderLoading,
              }}
            />
          </CustomTabPanel>
          {/*  */}
        </Box>
      )}
    </Card>
  )
}
