import {
    Box,
    IconButton,
} from "@mui/material";
import { MathJax } from "better-react-mathjax";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import viewIcon from "../../../assets/icons/EyeGreen.svg";
import editIcon from "../../../assets/icons/edit.svg";
import deleteIcon from "../../../assets/icons/trash_green.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useParams} from "react-router-dom";
import {useApi} from "../../../services/helpers";


  export const QuestionItem = ({
    question,
    index,
    setShowQuestionModal,
    lesson,
    setSelectedLesson,
    setShowQuestionViewModal,
    setRefreshQuestionsLessonId,
  }) => {
    const rowStyle = (index % 2 === 1) ? {backgroundColor: "rgba(106, 153, 78, 0.25)"} : {}
    //
      const { courseId } = useParams()
      const api = useApi()

      const handleDuplicate = () => {
          api.duplicateCourseLessonQuestion(courseId, lesson.id, question.id).handle({
              onSuccess: (res) => {
                  setRefreshQuestionsLessonId(lesson.id)
              },
              errorMessage: 'Error duplicating question',
              successMessage: 'Question duplicated successfully!',
          })
      }


    return (
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{
            flex: 1,
            paddingLeft: '5px',
            paddingRight:'25px',
            marginRight: '-25px',
            borderRadius:'10px',
            ...rowStyle
      }}>
        <MDTypography
          fontSize={{ xs: "18px", md: "20px" }}
          variant="h5"
          color="secondary"
          fontWeight="regular"
        >
          <MathJax>{`${index + 1}. ${question.prompt}`}</MathJax>
        </MDTypography>
        {/*  */}
        <Box>
        <IconButton
          onClick={() => {
            setShowQuestionModal({ isVisible: true, question: question });
            setSelectedLesson(lesson);
          }}
        >
          <img src={editIcon} alt="edit" />
        </IconButton>
        <IconButton onClick={handleDuplicate} color="primary">
            <ContentCopyIcon />
        </IconButton>
        <IconButton
            onClick={() => {
                setShowQuestionViewModal({ isVisible: true, question: { ...question, lesson_id: lesson.id }, viewMode: true, deleteMode: true });
                setSelectedLesson(lesson);
            }}
            color="primary"
        >
            <MDBox component={"img"} src={deleteIcon} alt="view" width={"25px"} />
        </IconButton>
        <IconButton
          onClick={() => {
            setShowQuestionViewModal({ isVisible: true, question: question, viewMode: true });
            setSelectedLesson(lesson);
          }}
          color="primary"
        >
          <MDBox component={"img"} src={viewIcon} alt="view" width={"25px"} />
        </IconButton>
        </Box>
    </MDBox>
    );
  };
